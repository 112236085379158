<template lang="pug">
head-meta(title-page="Выход" )
the-logout
</template>

<script>
import TheLogout from '../../components/login/TheLogout'
import HeadMeta from '../../components/_layout/HeadMeta'

export default {
  name: 'UserLogoutPage',
  components: { HeadMeta, TheLogout }
}
</script>
