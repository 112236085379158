<template lang="pug">
article.login
  .login__cont.cont
    h1.login__title.title Выйти из&nbsp;кабинета?
    p.login__text.text-big(v-if="user") {{ user.name }}, {{ user.email }}
    button.login__submit.button-white(type="button", @click="logout", :disabled="disabled") Выйти
</template>

<script>
import '@/assets/styles/components/login.sass'
import { getUser, logout, refreshUser } from '../../assets/scripts/API'

export default {
  name: 'TheLogout',
  data () {
    return {
      user: null,
      disabled: false
    }
  },
  methods: {
    async logout () {
      this.disabled = true
      const { status } = await logout()
      if (status === 'success') {
        await refreshUser()
        await this.$router.push({ name: 'Login' })
      }
    }
  },
  async mounted () {
    this.user = await getUser()
  }
}
</script>
